<template>
  <main>
    <package-header
      status="Defected"
      title="Edit Application"
      subTitle="12764 Smith"
      showValidate
    ></package-header>

    <div class="container-fluid">
      <div class="row">
        <div class="col">
          <div class="mb-3 border-bottom pb-3">
            <!-- Back to applications link -->
            <router-link class="mr-2" to="/pages/applications">
              <svg class="icon icon-arrow_cta_back mr-sm-1">
                <use
                  xlink:href="/icons/symbol-defs.svg#icon-arrow_cta_back"
                ></use>
              </svg>
              <span class="d-none d-sm-inline-block">Return to Package</span>
              <span class="d-sm-none">Back</span>
            </router-link>

            <!-- Change instrument dropdown -->
            <a
              href="javascript:void(0)"
              class="btn btn-md btn-secondary dropdown-toggle d-sm-inline-block"
              id="instrument"
              data-toggle="dropdown"
              aria-controls="instrument-contents"
              aria-expanded="false"
            >
              Go To Application
              <span class="caret"></span>
            </a>
            <div
              class="dropdown-menu"
              aria-labelledby="dropdownMenuButton"
            ></div>
          </div>

          <!-- Package title and actions  -->
          <div class="d-md-flex align-items-start justify-content-between">
            <div>
              <h2 class="page-title">Form-B-Anderson v2</h2>

              <ul class="list-inline mb-1">
                <li class="list-inline-item">
                  <small
                    ><span class="text-muted text-small">Status:</span>
                    <strong>Drafted</strong></small
                  >
                </li>
                <li class="list-inline-item">
                  <small
                    ><span class="text-muted">Modified: </span>
                    <strong>March 25, 2024, 3:42 PM</strong></small
                  >
                </li>
              </ul>
            </div>

            <!-- Drawer toggle and delete package -->
            <div class="d-flex justify-content-between">
              <button
                class="btn btn-md btn-secondary d-md-none mb-2"
                type="button"
                data-toggle="collapse"
                data-target="#application-nav"
                aria-expanded="false"
                aria-controls="application-nav"
              >
                <svg class="icon icon-more">
                  <use xlink:href="/icons/symbol-defs.svg#icon-more"></use>
                </svg>
                <svg class="icon icon-close">
                  <use xlink:href="/icons/symbol-defs.svg#icon-close"></use>
                </svg>
                Navigate Application
              </button>
              <button type="button" class="btn btn-md btn-danger mb-2">
                <svg class="icon icon-delete d-sm-none">
                  <use xlink:href="/icons/symbol-defs.svg#icon-delete"></use>
                </svg>
                <span class="d-none d-sm-block">Delete Application</span>
              </button>
            </div>
          </div>

          <!-- Main package content -->
          <div class="row mt-md-3">
            <!-- Step wizard -->
            <div class="col-md-3 mb-2 mb-md-4">
              <div class="collapse collapse-md" id="application-nav">
                <div class="step-wizard">
                  <div class="step">
                    <router-link
                      class="step-link"
                      to="/pages/edit-application/applicant"
                      >Applicant</router-link
                    >
                  </div>
                  <div class="step">
                    <router-link
                      class="step-link"
                      to="/pages/edit-application/pid-legal-description"
                      >Description of Land</router-link
                    >
                  </div>
                  <div class="step">
                    <router-link class="step-link" to="/pages/mortgage-borrower"
                      >Borrower (Mortgagor)</router-link
                    >
                  </div>
                  <div class="step">
                    <a class="step-link" href="javascript:void(0)"
                      >Lender (Mortgagee)</a
                    >
                  </div>
                  <div class="step">
                    <a class="step-link" href="javascript:void(0)"
                      >Payment Provision</a
                    >
                  </div>
                  <div class="step">
                    <a class="step-link" href="javascript:void(0)"
                      >Interest Mortgaged and Floating/Security Details</a
                    >
                  </div>
                  <div class="step">
                    <router-link class="step-link" to="/pages/mortgage-terms"
                      >Mortgage Terms</router-link
                    >
                  </div>
                  <div class="step">
                    <router-link
                      class="step-link"
                      to="/pages/edit-application/execution"
                      >Execution</router-link
                    >
                  </div>
                  <div class="step">
                    <a class="step-link" href="javascript:void(0)"
                      >Part 2 or Informational Document</a
                    >
                  </div>
                  <div class="step active">
                    <router-link
                      class="step-link"
                      to="/pages/edit-application/corrections-summary"
                      >Corrections Summary</router-link
                    >
                  </div>
                  <div class="step">
                    <a class="step-link" href="javascript:void(0)"
                      >Form Validation</a
                    >
                  </div>
                  <div class="step">
                    <a class="step-link" href="javascript:void(0)"
                      >Web Filing Form</a
                    >
                  </div>
                </div>
              </div>
            </div>

            <!-- Mortgage form entry -->
            <div class="col-md-9 col-lg-8">
              <div class="mb-3">
                <h2>Corrections Summary</h2>

                <div class="mb-4 p-2 p-md-4 bg-medium">
                  <h3>Declaration Statement</h3>
                  <p>
                    I <strong>{Applicant Name}</strong> the applicant, state
                    that:
                  </p>

                  <ol class="mb-0">
                    <li>
                      The application was submitted with an error, omission or
                      misdescription.
                    </li>
                    <li>
                      The revisions are based on personal knowledge or
                      reasonable belief.
                    </li>
                    <li>
                      I have consent of all owners listed in the application to
                      make corrective revisions to the document.
                    </li>
                  </ol>
                </div>

                <div class="mb-4 p-2 p-md-4 bg-medium">
                  <h3>Revisions Made</h3>
                  <div class="card mb-3">
                    <div class="card-body">
                      <h4 class="card-title">Applicant</h4>
                      <ul class="mb-0">
                        <li>
                          Applicant Name "Jane Doe"
                          <ul>
                            <li>
                              Last Name changed
                              <ul class="list-unstyled pl-3">
                                <li>from "Doe"</li>
                                <li>to "Smith"</li>
                              </ul>
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </div>
                    <div class="card-footer">
                      <button class="btn btn-md btn-link pl-0">Edit</button>
                      <button class="btn btn-md btn-link px-0 text-danger">
                        Discard Revisions
                      </button>
                    </div>
                  </div>

                  <div class="card mb-3">
                    <div class="card-body">
                      <h4 class="card-title">Applicant</h4>
                      <ul class="mb-0">
                        <li>
                          Witnessing Officer - Officer Name "Jerry Swim"
                          <ul>
                            <li>
                              Officer Name changed
                              <ul class="list-unstyled pl-3">
                                <li>from "Swim"</li>
                                <li>to "Swims"</li>
                              </ul>
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </div>
                    <div class="card-footer">
                      <button class="btn btn-md btn-link pl-0">Edit</button>
                      <button class="btn btn-md btn-link px-0 text-danger">
                        Discard Revisions
                      </button>
                    </div>
                  </div>

                  <div class="card">
                    <div class="card-body">
                      <h4 class="card-title">
                        Part 2 or Informational Document
                      </h4>
                      <ul class="mb-0">
                        <li>Uploaded new document "{Filename}"</li>
                        <li>Removed uploaded document "{Filename}"</li>
                      </ul>
                    </div>
                    <div class="card-footer">
                      <button class="btn btn-md btn-link pl-0">Edit</button>
                      <button class="btn btn-md btn-link px-0 text-danger">
                        Discard Revisions
                      </button>
                    </div>
                  </div>
                </div>

                <div class="mb-4 p-2 p-md-4 bg-medium">
                  <h3>Additional Information</h3>
                  <div class="form-group mb-0">
                    <label for="additionalInformation"
                      >Provide any additional information about the corrections
                      to this application that are not included in the revisions
                      made. (optional)</label
                    >
                    <textarea
                      class="form-control"
                      name="additionalInformation"
                      id="additionalInformation"
                      rows="4"
                    ></textarea>
                  </div>
                </div>

                <div class="mb-4 p-2 p-md-4 bg-medium">
                  <h3>Add Declaration Statement</h3>
                  <label class="mb-1"
                    >Do you need to add a declaration statement?</label
                  >
                  <div class="form-group mb-0">
                    <div
                      class="custom-control custom-radio custom-control-inline"
                    >
                      <input
                        type="radio"
                        id="declarationStatementYes"
                        name="declarationStatementYes"
                        class="custom-control-input"
                        value="yes"
                        v-model="declarationStatement"
                      />
                      <label
                        class="custom-control-label"
                        for="declarationStatementYes"
                        >Yes</label
                      >
                    </div>
                    <div
                      class="custom-control custom-radio custom-control-inline"
                    >
                      <input
                        type="radio"
                        id="declarationStatementNo"
                        name="declarationStatementNo"
                        class="custom-control-input"
                        value="no"
                        v-model="declarationStatement"
                      />
                      <label
                        class="custom-control-label"
                        for="declarationStatementNo"
                        >No</label
                      >
                    </div>
                  </div>

                  <div v-if="declarationStatement === 'yes'">
                    <div class="form-group mt-2">
                      <label
                        class="d-flex align-items-center"
                        for="selectStatement"
                        >Select what statement you want to add to this
                        application.
                        <button
                          class="btn btn-link p-0 ml-1"
                          data-toggle="popover"
                          data-placement="right"
                          data-content="{Help content}"
                          v-popover
                        >
                          <svg class="icon icon-help">
                            <use
                              xlink:href="/icons/symbol-defs.svg#icon-help"
                            ></use>
                          </svg>
                        </button>
                      </label>
                      <select
                        class="form-control"
                        name="selectStatement"
                        id="selectStatement"
                      >
                        <option value="AB">Form F Statement</option>
                      </select>
                    </div>

                    <label class="sr-only" for="statement">Statement</label>
                    <textarea
                      class="form-control"
                      rows="6"
                      name="statement"
                      id="statement"
                      disabled
                    >
I have in my possession a Form F, Certificate of Payment under the Strata Property Act, that is for the Strata lot(s) specified in the accompanying document or in the related document noted above.  I confirm that the Form F is current for the purposes of Section 115(2) of the Strata Property Act and that it has been signed on behalf of the Strata Corporation.  The Form F states that no money is owing to the strata corporation or where money is owing, that the provisions of Section 114, Strata Property Act, have been met or that arrangements satisfactory to the strata corporation have been made to pay the money owing.</textarea
                    >
                  </div>
                </div>

                <div class="mb-4 p-2 p-md-4 bg-medium">
                  <h3>Add Additional Supporting Documents</h3>
                  <p>
                    The application may require additional documents or evidence
                    to verify the requirements of registration are met.
                  </p>

                  <!--<label
                    >Do you have additional supporting documents to add?</label
                  >

                  <div class="form-group mb-0">
                    <div
                      class="custom-control custom-radio custom-control-inline"
                    >
                      <input
                        type="radio"
                        id="supportingDocumentsYes"
                        name="supportingDocumentsYes"
                        class="custom-control-input"
                        value="yes"
                        v-model="supportingDocuments"
                      />
                      <label
                        class="custom-control-label"
                        for="supportingDocumentsYes"
                        >Yes</label
                      >
                    </div>
                    <div
                      class="custom-control custom-radio custom-control-inline"
                    >
                      <input
                        type="radio"
                        id="supportingDocumentsNo"
                        name="supportingDocumentsNo"
                        class="custom-control-input"
                        value="no"
                        v-model="supportingDocuments"
                      />
                      <label
                        class="custom-control-label"
                        for="supportingDocumentsNo"
                        >No</label
                      >
                    </div>
                  </div>-->

                  <supporting-documents></supporting-documents>
                </div>
              </div>

              <!-- Save and continue -->
              <div
                class="d-flex flex-column-reverse flex-sm-row justify-content-sm-between d-print-none"
              >
                <div class="mt-2 mt-sm-0">
                  <button class="btn btn-secondary">Back</button>
                </div>
                <div class="d-flex justify-content-between">
                  <button class="btn btn-secondary mr-1">Save Progress</button>
                  <button class="btn btn-primary">
                    Continue
                    <svg class="icon icon-arrow_cta ml-1">
                      <use
                        xlink:href="/icons/symbol-defs.svg#icon-arrow_cta"
                      ></use>
                    </svg>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import PackageHeader from '../../../components/PackageHeader.vue';
import PackageNavigation from '../../../components/PackageNavigation.vue';
import SupportingDocuments from '../../../components/SupportingDocuments.vue';

export default {
  components: {
    'package-header': PackageHeader,
    'package-navigation': PackageNavigation,
    'supporting-documents': SupportingDocuments,
  },

  data() {
    return {
      declarationStatement: '',
      supportingDocuments: '',
    };
  },
};
</script>
