<template>
  <div>
    <!-- Existing Related Documents or plan numbers -->
    <div class="mt-2">
      <div class="d-flex justify-content-between align-items-center">
        <h4 class="mb-0">
          Supporting Documents <i>({{ documents.length }})</i>
        </h4>
        <button
          class="btn btn-tertiary btn-sm"
          @click="formOpen = !formOpen"
          v-if="!formOpen"
        >
          Add New
        </button>
      </div>

      <!-- Add new PID form -->
      <form class="mt-3 mb-4" v-if="formOpen">
        <div class="form-group">
          <label for="description">Brief description</label>
          <div class="input-group">
            <input
              class="form-control"
              type="text"
              name="description"
              id="description"
              v-model="description"
            />
          </div>
        </div>

        <div class="form-group">
          <label for="fileToUpload">PDF file</label>
          <div class="input-group">
            <input
              class="form-control"
              type="file"
              name="fileToUpload"
              id="fileToUpload"
            />
          </div>
        </div>

        <button
          class="btn btn-md btn-tertiary"
          @click.prevent="addRow(description)"
        >
          Add to List
        </button>
        <button class="btn btn-md btn-link" @click="formOpen = false">
          Cancel
        </button>
      </form>

      <table class="mt-2 table table-stacked table-select-row bg-white">
        <thead>
          <tr>
            <th class="text-nowrap" scope="col">Filename</th>
            <th scope="col">Description</th>
            <th scope="col" class="text-right">Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(file, index) in documents" :key="index">
            <td scope="col" data-header="Filename">
              <span class="td-content">pdf-filename-{{ index + 1 }}.pdf</span>
            </td>
            <td scope="col" data-header="Description">
              {{ file.description }}
            </td>
            <td class="text-right" scope="col" data-header="Actions">
              <button
                class="btn btn-sm btn-link text-danger pr-0"
                @click="removeRow(index)"
              >
                Remove
              </button>
            </td>
          </tr>
          <tr v-if="documents.length == 0">
            <td colspan="3">
              <div class="d-flex align-items-center">
                <svg class="icon icon-alert icon-lg mr-1 text-info">
                  <use xlink:href="/icons/symbol-defs.svg#icon-alert"></use>
                </svg>
                There are currently no supporting documents added.
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      formOpen: false,
      description: null,
      documents: [],
    };
  },

  methods: {
    addRow(description) {
      this.documents.push({ description: description, editing: false });
      this.formOpen = false;
      this.description = null;
    },

    removeRow(index) {
      this.documents.splice(index, 1);
    },

    editFile(index) {
      this.documents[index].editing = true;
    },
  },
};
</script>
